import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'checkbox', 'allPagesCheckbox', 'rowCheckbox', 'pageButton' , 'menuItem']

  initialize() {
    this.actionButton = $('.action-button');

    this.initializeCheckboxSelection()
  }

  initializeCheckboxSelection() {
    if (this.urlSearchParamsForQuery(window.location.search).get('selectAllPages') == 'true') {
      this.toggleAllCheckboxes();
    }
  }

  allPagesSelected() {
    return $(this.allPagesCheckboxTarget).is(':checked');
  }

  checkedRowsRecordIds() {
    const checkedRowCheckboxes = $(this.rowCheckboxTargets).filter(':checked')

    return $.map(checkedRowCheckboxes, function (checkbox) {
      return $(checkbox).data('record-id')
    })
  }

  handleSelectAll(event) {
    event.preventDefault()
    this.toggleAllCheckboxes()
  }

  handleSelectRow(event) {
    this.untickCheckbox(this.allPagesCheckboxTarget)

    this.toggleActionButton()
    this.setIndividualCheckboxParams(event.currentTarget)
  }

  paramsFromAllPagesCheckbox() {
    return $(this.allPagesCheckboxTarget).data('params')
  }

  persistSelection() {
    const shouldSetParams = this.allPagesSelected()
    const that = this

    $(this.pageButtonTargets).each(function(index) {
      const searchParams = that.urlSearchParamsForQuery(this.search)
      shouldSetParams ? searchParams.set('selectAllPages', true) : searchParams.delete('selectAllPages', true)
      this.search = searchParams.toString()
    })
  }

  setIndividualCheckboxParams(checkbox) {
    this.setParamsOnExportButton($.param({ id_in: this.checkedRowsRecordIds() }))
    this.setParamsOnActionMenuItems($.param({ id_in: this.checkedRowsRecordIds() }))
  }

  setParamsOnActionMenuItems(params) {
    $(this.menuItemTargets).each(function(index) {
      this.value = params
    })
  }

  setParamsOnExportButton(params) {
    const paramsInput = this.actionButton.siblings('input[name="ransack_params"]')
    paramsInput.val(params)
  }

  toggleAllCheckboxes() {
    $(this.allPagesCheckboxTarget).prop('checked', function(i, v) { return !v; });

    if (this.allPagesSelected()) {
      this.tickCheckbox(this.rowCheckboxTargets)
    } else {
      this.untickCheckbox(this.rowCheckboxTargets)
    }

    this.toggleActionButton()
    this.setAllPagesCheckboxParams(this.paramsFromAllPagesCheckbox())
    this.persistSelection()
  }

  setAllPagesCheckboxParams(params) {
    this.setParamsOnExportButton(params)
    this.setParamsOnActionMenuItems(params)
  }

  toggleActionButton() {
    if ($(this.checkboxTargets).is(':checked')) {
      this.actionButton.prop('disabled', false);
    } else {
      this.actionButton.prop('disabled', true);
    }
  }

  tickCheckbox(checkbox) {
    $(checkbox).prop('checked', true);
  }

  untickCheckbox(checkbox) {
    $(checkbox).prop('checked', false);
  }

  urlSearchParamsForQuery(queryParams) {
    return new URLSearchParams(queryParams)
  }
}
