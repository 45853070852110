import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "ellipsizeLeftInput", "defaultValueInput" ]

  handleSubmit(_event) {
    this.trimTextInputs();
  }

  setDefaultValue(event) {
    if (this.defaultValueInputTarget.value === '') {
      this.defaultValueInputTarget.value = event.params.defaultValue;
    }
  }

  trimTextInputs() {
    const textInputs = $(this.formTarget).find('input[type=text]')

    textInputs.each(function() {
      $(this).val($.trim($(this).val()));
    });
  }

  toggleEllipsizeLeft() {
    $(this.ellipsizeLeftInputTargets).each(function() {
      $(this).toggleClass('ellipsize-left')
    })
  }
}
