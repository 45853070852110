import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['finished', 'accordion']
  static values = { url: String, refreshInterval: String }

  connect() {
    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    // Stop polling if the the item has finished
    if (this.finishedTarget.dataset.finished == "true") {
      this.stopRefreshing()
      return
    }

    const fullUrl = this.buildFullUrl()

    fetch(fullUrl, { headers: { "Accept": "application/javascript" } })
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  buildFullUrl() {
    if (this.hasAccordionTarget) {
      const openAccordions = this.accordionTargets
                                 .filter(accordion => accordion.matches('.show'))
                                 .map(accordion => accordion.dataset.id)

      return this.urlValue + '?' + $.param({ toggled_accordion_ids: openAccordions })
    } else {
      return this.urlValue
    }
  }
}
