import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  static values = { endpoint: String, multiple: Boolean, preselected: Object }

  initialize () {
    this.initializeInput()
  }

  initializeInput () {
    const select2Input = $(this.inputTarget)
    const multiple = (this.hasMultipleValue && this.multipleValue === true)
    const preselected = this.preselectedValue

    for (const [key, value] of Object.entries(preselected)) {
      let option = new Option(key, value, true, true);
      select2Input.append(option).trigger('change')
    }

    select2Input.select2({
      ajax: {
        delay: 150,
        url: this.endpointValue,
        data: function (params) {
          var query = {
            q: params.term,
            page: params.page || 1
          }

          return query;
        }
      },

      dropdownParent: $(this.inputTarget).parent(),
      minimumInputLength: 3,
      multiple: multiple,
      width: '100%'
    });

    // The following is a workaround to force the select2 input to focus when it is opened. The workaround is only
    // needed until either the jQuery bug is resolved or select2 ships a fix.
    //   - see: https://github.com/select2/select2/issues/5993
    //   - see: https://github.com/jquery/jquery/issues/4382
    $(this.inputTarget).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    })
  }
}
