import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'editor', 'textarea' ]
  static values = { language: String, readOnly: Boolean }

  connect() {
    this.setEditor()
    this.loadContents()
    this.setFormSubmissionHandler()
  }

  disconnect() {
    if (this.form) {
      this.form.removeEventListener('submit', this.submitHandler)
    }
  }

  setEditor() {
    ace.config.loadModule('ace/ext/searchbox')

    const editor = ace.edit(this.editorTarget)
    editor.setOptions({
      mode: 'ace/mode/' + this.languageValue,
      readOnly: this.isReadOnly(),
      showInvisibles: true,
      showPrintMargin: false,
      tabSize: 2,
      theme: 'ace/theme/nord_dark',
      useSoftTabs: true,
    })
    this.editor = editor
  }

  loadContents() {
    this.editor.setValue(this.textareaTarget.value)
    this.editor.clearSelection()
  }

  setFormSubmissionHandler() {
    this.submitHandler = this.setRawContent.bind(this)
    this.form = this.textareaTarget.form

    if (this.form) {
      this.form.addEventListener('submit', this.submitHandler)
    }
  }

  setRawContent() {
    this.textareaTarget.value = this.editor.getValue()
  }

  isReadOnly() {
    return this.hasReadOnlyValue ? this.readOnlyValue : false;
  }
}
