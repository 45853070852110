import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "row" ]

  removeBlankRows(event) {
    const that = this
    const rows = $(this.rowTargets)

    $.each(rows, function (index, row) {
      const rowIsVisible = $(row).is(':visible')
      const rowInputs = $(row).find('input')
      const allEmpty = _.every(rowInputs, function (rowInput) { return that.inputIsBlank(rowInput) })

      if (rowIsVisible && allEmpty) { $(row).remove() }
    })
  }

  inputIsBlank(inputElement) {
    return $.trim($(inputElement).val()) === ''
  }

  toggleSubElement(event) {
    const selectMenu = event.currentTarget
    const currentSelection = selectMenu.value
    const subElement = $('#' + selectMenu.dataset['subElementId'])
    const subElementShownOn = selectMenu.dataset['subElementShownOn'].split(',')

    if (subElementShownOn.includes(currentSelection)) {
      subElement.fadeIn('fast');
    } else {
      subElement.fadeOut('fast');
    }
  }
}
