import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleableRow", "displayToggle" ]
  static values =  { display: String, reset: String }

  connect() {
    if (this.hasDisplayToggleTarget) {
      this.toggleRowDisplay()
    }
  }

  clearRowInputs(row) {
    $(row).find('input:not([type=radio])').val('')
    $(row).find('input[type=radio]').prop('checked', false)
  }

  resetRowInputs(row) {
    this.resetRowRadioInputs(row)
  }

  resetRowRadioInputs(row) {
    const resetValue = this.resetValue

    $(row).find('input[type=radio]').each(function() {
      let input = $(this)

      if (input.val() == resetValue) {
        input.prop('checked', true)
      } else {
        input.prop('checked', false)
      }
    })
  }

  toggleRowDisplay() {
    const row = $(this.toggleableRowTarget)

    if (this.displayToggleTarget.value == this.displayValue) {
      row.show()
      this.resetRowInputs(row)
    } else {
      row.hide()
      this.clearRowInputs(row)
    }
  }
}
