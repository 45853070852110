import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "parentInput", "childInput", "childLabel" ]

  connect() {
    this.toggleChildInput()
  }

  toggleChildInput() {
    if (this.hasChildInputTarget) {
      const parentInput = $(this.parentInputTarget)
      const parentSelectedValue = parentInput.find(':selected').text()
      const requiredValueSubStr = $(this.childInputTarget).data('required-parent-value-substring')

      if (parentSelectedValue.includes(requiredValueSubStr)) {
        this.childInputTarget.setAttribute('required', true)
        this.childLabelTarget.classList.add('required');
      } else {
        this.childInputTarget.removeAttribute('required')
        this.childLabelTarget.classList.remove('required');
      }
    }
  }
}
