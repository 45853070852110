import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "row" ]

  addRow(event) {
    const lastRow = $(this.rowTargets).last()

    if (lastRow.is(':visible')) {
      const newRow = lastRow.clone()

      this.clearRowInputs(newRow)
      newRow.insertAfter(lastRow)
    } else {
      lastRow.show()
    }
  }

  deleteRow(event) {
    const row = $(event.currentTarget).closest('.dynamic-form-field-row')

    if (this.rowTargets.length === 1) {
      row.hide()
      this.clearRowInputs(row)
    } else {
      row.remove()
    }
  }

  clearRowInputs(row) {
    $(row).find('input').val('')
  }
}
