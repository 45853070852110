import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      this.openRequestedModal();
    }
  }

  openRequestedModal() {
    const queryString = window.location.search
    const searchParams = new URLSearchParams(queryString)
    const modalId = searchParams.get('modal')

    if (modalId) {
      return $(modalId).modal('show')
    }
  }

  populateRemoteModal(event) {
    const contentPath = $(event.currentTarget).data('contentPath')
    return $.get(contentPath)
  }

  replaceModal(event) {
    const modal = $(event.currentTarget).closest('.modal')
    const replacementModal = $($(event.currentTarget).data('replacementModalId'))

    modal.modal('hide')
    modal.on('hidden.bs.modal', function (e) {
      replacementModal.modal('show')
    })
  }
}
