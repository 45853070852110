import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "relatedInput" ]

  connect() {
    this.checkboxTargets.forEach((checkbox) => this.toggleStateOfRelatedInputs(checkbox))
  }

  handleChange(event) {
    this.toggleStateOfRelatedInputs(event.currentTarget)
  }

  toggleStateOfRelatedInputs(checkbox) {
    this.relatedInputTargets.forEach((relatedInput) => relatedInput.disabled = checkbox.checked)
  }
}
